import React from 'react';
//import './Affiliates.css'; // Optional: Use for additional custom styling

const Affiliates = () => {
  return (
    <div style={{ backgroundColor: '#FFFFFF', color: '#000000', padding: '20px', fontFamily: 'Times New Roman' }}>
      <h1 style={{ fontSize: '2.5rem', textAlign: 'center' }}>Webmaster Referral Signup</h1>
      <p>&nbsp;</p>

      {/* New Account Form */}
      <form action="https://admin.ccbill.com/signup.cgi" method="POST">
        <input type="hidden" name="CA" value="934250-0003" />
        <input type="hidden" name="GR" value="" />
        <input type="hidden" name="page_background" value="" />
        <input type="hidden" name="page_bgcolor" value="#FFFFFF" />
        <input type="hidden" name="page_text" value="#000000" />
        <input type="hidden" name="page_link" value="blue" />
        <input type="hidden" name="page_vlink" value="purple" />
        <input type="hidden" name="page_alink" value="blue" />
        <input type="hidden" name="table_left" value="#AEAEFF" />
        <input type="hidden" name="table_right" value="#FEFFC1" />
        <input type="hidden" name="table_text" value="#000000" />
        <input type="hidden" name="star_color" value="#CC0000" />
        <input type="hidden" name="second" value="1" />

        <p>If you have not yet signed up under a CCBill-sponsored affiliate program you can:</p>
        <input type="submit" value="Create a new account" style={{ fontSize: '1rem' }} />
      </form>

      <br /><br />

      {/* Retrieve Data Form */}
      <p>To load your current data, please enter your ID, username, and password.</p>
      <form action="https://admin.ccbill.com/signup.cgi" method="POST">
        <input type="hidden" name="CA" value="934250-0003" />
        <input type="hidden" name="GR" value="" />
        <input type="hidden" name="page_background" value="" />
        <input type="hidden" name="page_bgcolor" value="#FFFFFF" />
        <input type="hidden" name="page_text" value="#000000" />
        <input type="hidden" name="page_link" value="blue" />
        <input type="hidden" name="page_vlink" value="purple" />
        <input type="hidden" name="page_alink" value="blue" />
        <input type="hidden" name="table_left" value="#AEAEFF" />
        <input type="hidden" name="table_right" value="#FEFFC1" />
        <input type="hidden" name="table_text" value="#000000" />
        <input type="hidden" name="star_color" value="#CC0000" />
        <input type="hidden" name="second" value="1" />

        <table style={{ borderSpacing: '2px', width: '30%', margin: '0 auto', borderCollapse: 'collapse' }}>
          <tbody>
            <tr style={{ verticalAlign: 'middle', textAlign: 'left' }}>
              <td style={{ backgroundColor: '#AEAEFF', padding: '10px' }}>Current ID</td>
              <td style={{ backgroundColor: '#FEFFC1', padding: '10px' }}>
                <input type="text" name="EID" size="15" maxLength="15" />
              </td>
            </tr>
            <tr style={{ verticalAlign: 'middle', textAlign: 'left' }}>
              <td style={{ backgroundColor: '#AEAEFF', padding: '10px' }}>Username</td>
              <td style={{ backgroundColor: '#FEFFC1', padding: '10px' }}>
                <input type="text" name="username" size="15" maxLength="15" />
              </td>
            </tr>
            <tr style={{ verticalAlign: 'middle', textAlign: 'left' }}>
              <td style={{ backgroundColor: '#AEAEFF', padding: '10px' }}>Password</td>
              <td style={{ backgroundColor: '#FEFFC1', padding: '10px' }}>
                <input type="password" name="password" size="15" maxLength="15" />
              </td>
            </tr>
            <tr style={{ verticalAlign: 'middle', textAlign: 'left' }}>
              <td style={{ padding: '10px' }}>&nbsp;</td>
              <td style={{ padding: '10px' }}>
                <input type="submit" value="Retrieve data" />
                <input type="reset" value="Clear" style={{ marginLeft: '10px' }} />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default Affiliates;
