import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const FreePhotoDetails = () => {
  const { foldername } = useParams();
  const [thumbnails, setThumbnails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchThumbnails = async () => {
      const apiUrl = `/api/freethumbnails/${foldername}`;
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        setThumbnails(json); // Assuming json is an array of filenames
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchThumbnails();
  }, [foldername]);

  if (isLoading) return <Container>Loading...</Container>;
  if (error) return <Container>Error: {error}</Container>;

  return (
    <Container>
      <h1>Free Photo Details for {foldername}</h1>
      <Row>
        {thumbnails.map((filename, index) => (
          <Col key={index} xs={6} md={4} lg={3}>
            <Link to={`/members/freephotos/${foldername}/${filename}`}>
              <img 
                src={`/members/freephotosthumbs/${foldername}/${filename}`} 
                alt={filename} 
                className="img-fluid"
              />
            </Link>
          </Col>
        ))}
      </Row>
      <footer className="text-center mt-4">
        <a
                href="https://thehun.net"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                src="https://assets.thehun.net/images/thehun/reciprocal/thehun.gif"
                alt="The Hun"
                style={{ verticalAlign: 'middle' }}
                />
            </a>
            <a
                href="http://www.pinkworld.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '10px' }}
            >
                Visit Pink World
            </a>
            <a
                href="https://www.pornpitstop.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '10px' }}
            >
                Visit Porn Pit Stop
            </a>
    </footer>

    </Container>
  );
};

export default FreePhotoDetails;
