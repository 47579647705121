import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Search.css';

const Search = () => {
  const { searchTerm } = useParams();
  const [results, setResults] = useState({ photos: [], videos: [], vods: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResults = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`/api/search/${searchTerm}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setResults(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchResults();
  }, [searchTerm]);

  if (isLoading) return <Container>Loading...</Container>;
  if (error) return <Container>Error: {error}</Container>;

  return (
    <Container className="search-results">
      <h1>Search Results for: {searchTerm}</h1>
      <Row>
        <h2 className="section-title">Photos</h2>
        {results.photos.map((photo, index) => (
         <Col key={index} md={2} className="mb-4 search-column">
            <Card className="search-card">
            <Link to={`/photos/${photo.path}`}>
                <div className="image-container">
                    <Card.Img variant="top" src={`/members/photosthumbs/${photo.path}/large.jpg`} />
                </div>
                <Card.Body>
                <Card.Title>{photo.updatename}</Card.Title>
                <Card.Text>{photo.modelname}</Card.Text>
                </Card.Body>
            </Link>
            </Card>
         </Col>
        ))}
        <h2 className="section-title">Videos</h2>
        {results.videos.map((video, index) => (
          <Col key={index} md={2} className="mb-4">
            <Card className="search-video-card">
              <Link to={`/video/${video.path}`}>
                <div className="video-container">
                    <Card.Img variant="top" src={`/members/videothumbs/${video.path}/${video.path}_02.jpg`} />
                </div>
                <Card.Body>
                  <Card.Title>{video.updatename}</Card.Title>
                  <Card.Text>{video.modelname}</Card.Text>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        ))}
        <h2 className="section-title">VOD</h2>
        {results.vods.map((vod, index) => (
          <Col key={index} md={2} className="mb-4">
            <Card className="search-vod-card">
              <Link to={`/vod/${vod.path}`}>
                <div className="vod-container">
                    <Card.Img variant="top" src={`/members/vodthumbs/${vod.path}/${vod.path}_02.jpg`} />
                </div>
                <Card.Body>
                  <Card.Title>{vod.updatename}</Card.Title>
                  <Card.Text>{vod.modelname}</Card.Text>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Search;
