import React from 'react';
import { useParams } from 'react-router-dom';
import './ImageDisplay.css'; // Make sure to import the CSS file

const ImageDisplay = () => {
  const { folderName, imageName } = useParams();

  return (
    <div className="image-display-container">
      <img src={`/members/photos/${folderName}/${imageName}`} alt={`${folderName}/${imageName}`} />
    </div>
  );
};

export default ImageDisplay;
