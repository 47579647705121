import React from 'react';
import { useParams } from 'react-router-dom';

const VideoDisplay = () => {
  const { folderName, fileName } = useParams();

  return (
    <div>
      <img src={`/members/video/${folderName}/${fileName}`} alt={`${folderName}/${fileName}`} />
    </div>
  );
};

export default VideoDisplay;