import React from 'react';
import { Container } from 'react-bootstrap';

const PrivateSales = () => {
    return (
        <Container className="contact-page">
          <h1 className="text-center my-4">Private Sales</h1>
          <p>We have unreleased content of many models, including:</p>
            <ul>
            <li>Julie Bonnett</li>
            <li>Shonda Lee</li>
            <li>Jade Tice</li>
            <li>Fanny Swerkstrom</li>
            <li>Alicia Romero</li>
            <li>Karie Ryan</li>
            <li>Jessica Vetter</li>
            <li>Hayley Nasby</li>
            <li>Courtney Eichorn</li>
            <li>Stefanie Richard</li>
            </ul>
            <p>Plus, many other unseen content of your favorite models is available exclusively for private sale, much of it is topless or nude. Please inquire about any model you are interested in!</p>
            <p>Email <a href="mailto:muscletease@gmail.com?subject=Private%20Sales">muscletease@gmail.com</a> for more information.</p>
        </Container>
      );
};

export default PrivateSales;
