import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import './Models.css'; // Make sure your CSS file path is correct

const Models = () => {
  const [models, setModels] = useState([]);
  const [selectedLetter, setSelectedLetter] = useState('A');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchModels = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`/api/models/${selectedLetter}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setModels(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchModels();
  }, [selectedLetter]);

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  if (isLoading) {
    return <Container>Loading...</Container>;
  }

  if (error) {
    return <Container>Error: {error}</Container>;
  }

  return (
    <Container className="model-gallery">
      <h1 className="text-center my-4">Models</h1>
      <Row className="justify-content-center mb-4">
        {alphabet.map((letter) => (
          <Col key={letter} xs="auto">
            <button
              className={`letter-button ${selectedLetter === letter ? 'active' : ''}`}
              onClick={() => setSelectedLetter(letter)}
            >
              {letter}
            </button>
          </Col>
        ))}
      </Row>
      <Row xs={2} md={4} lg={6} className="g-4">
        {models.map((model) => (
          <Col key={model.modelname} className="model-column">
            <Link to={`/models/${encodeURIComponent(model.modelname)}`} className="model-link">
              <LazyLoad height={200} offset={100} once>
                <div className="model-thumbnail-wrapper">
                  <img src={model.thumbnail} alt={model.modelname} className="img-thumbnail" />
                </div>
              </LazyLoad>
              <div className="model-info">{model.modelname}</div>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Models;
