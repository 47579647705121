import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import './Home.css';


const Home = () => {
    const [updates, setUpdates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [showAgeModal, setShowAgeModal] = useState(!localStorage.getItem('ageConfirmed'));

    useEffect(() => {
        // Check if the user has already confirmed their age
        const ageConfirmed = localStorage.getItem('ageConfirmed');
        if (!ageConfirmed) {
            setShowAgeModal(true);
        }
    }, []);

    useEffect(() => {
        const fetchUpdates = async () => {
            try {
                const response = await fetch(`/api/updates/${currentPage}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setUpdates(prevUpdates => [...prevUpdates, ...data]);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        };

        fetchUpdates();
    }, [currentPage]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
            setCurrentPage(currentPage + 1);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [currentPage]);

    const handleAgeConfirmation = (isConfirmed) => {
        if (isConfirmed) {
          localStorage.setItem('ageConfirmed', 'true');
        } else {
          window.location.href = 'https://www.google.com';
        }
        setShowAgeModal(false);
      };

    if (isLoading && currentPage === 1) return <Container>Loading...</Container>;
    if (error) return <Container>Error: {error}</Container>;

    // Separate updates into photos, videos, and vods
    const photos = updates.filter(update => update.type === 'photo');
    const videos = updates.filter(update => update.type === 'video');
    const vods = updates.filter(update => update.type === 'vod');

    return (
        <Container className="home-page">
            <Row className="mt-0">
                <Col className="text-center">
                    <Link to="/2257">2257 Compliance Statement</Link>
                </Col>
            </Row>
            <Row>
            <Col md={4} className="updates-column">
                <h2>Latest Photos</h2>
                {photos.map((photo, index) => (
                    <LazyLoad key={index} height={200} offset={100} once>
                        <Card className="photo-update-card">
                            <Link to={`/photos/${photo.path}`}>
                                <div className="photo-thumbnail">
                                    <Card.Img variant="top" src={`${photo.thumbnail}`} style={{ objectFit: 'cover' }} />
                                </div>
                                <Card.Body>
                                    <Card.Title>{photo.updatename}</Card.Title>
                                    <Card.Text>{photo.modelname}</Card.Text>
                                    <Card.Text>{photo.datetoupload}</Card.Text>
                                </Card.Body>
                            </Link>
                        </Card>
                    </LazyLoad>
                ))}
            </Col>
                <Col md={4} className="updates-column">
                    <h2>Latest Videos</h2>
                    {videos.map((video, index) => (
                        <LazyLoad key={index} height={200} offset={100} once>
                            <Card className="video-card">
                                <Link to={`/video/${video.path}`}>
                                    <Card.Img variant="top" src={`${video.thumbnail}`} />
                                    <Card.Body>
                                        <Card.Title>{video.updatename}</Card.Title>
                                        <Card.Text>{video.modelname}</Card.Text>
                                        <Card.Text>{video.datetoupload}</Card.Text>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </LazyLoad>
                    ))}
                </Col>
                <Col md={4} className="updates-column">
                    <h2>Latest VODs</h2>
                    {vods.map((vod, index) => (
                        <LazyLoad key={index} height={200} offset={100} once>
                            <Card className="vod-card">
                                <Link to={`/vod/${vod.path}`}>
                                    <Card.Img variant="top" src={`${vod.thumbnail}`} />
                                    <Card.Body>
                                        <Card.Title>{vod.updatename}</Card.Title>
                                        <Card.Text>{vod.modelname}</Card.Text>
                                        <Card.Text>{vod.datetoupload}</Card.Text>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </LazyLoad>
                    ))}
                </Col>
            </Row>
            
        </Container>
    );
};

export default Home;
