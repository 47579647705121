import React, { useState, useEffect } from 'react';

const PaymentForm = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (v, i) => currentYear + i);
    const months = Array.from({ length: 12 }, (v, i) => (i + 1).toString().padStart(2, '0'));

    const [formData, setFormData] = useState({
        // username: 'test',
        // password: 'tester',
        // nameOnCard: 'John Doe',
        // cardNumber: '5105105105105100',
        // expMonth: '12',
        // expYear: '2030',
        // cvv2: '123',
        // firstName: 'John',
        // lastName: 'Doe',
        // customerName: 'John Doe',
        // address1: '123 Main St',
        // address2: 'Suite 500',
        // city: 'Anytown',
        // country: 'US',
        // state: 'CA',
        // postalCode: '90210',
        // phoneNumber: '555-1234',
        // email: 'john.doe@example.com',
        username: '',
        password: '',
        email: '',
        nameOnCard: '',
        cardNumber: '',
        expMonth: '',
        expYear: '',
        cvv2: '',
        firstName: '',
        lastName: '',
        customerName: '',
        address1: '',
        address2: '',
        city: '',
        country: '',
        state: '',
        postalCode: '',
        phoneNumber: '',
        currencyCode: '840',
        ipAddress: '192.168.1.1',
        customerInfo: JSON.stringify({ info: "Example customer information" }),
        browserHttpAccept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
        browserHttpAcceptEncoding: 'gzip, deflate, br',
        browserHttpAcceptLanguage: 'en-US,en;q=0.5',
        initialPrice: 14.99,
        initialPeriod: 30,
        recurringPrice: 14.99,
        recurringPeriod: 30,
        rebills: 99,
        lifeTimeSubscription: false,
        createNewPaymentToken: false,
        passThroughInfo: [
            { name: 'custom1', value: '10000000942' },
            { name: 'custom2', value: '10058' }
        ]
    });
    const [transactionStatus, setTransactionStatus] = useState('');

    // Dynamically load the CCBill widget script
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://js.ccbill.com/v1.3.0/ccbill-advanced-widget.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // Handle form data changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setTransactionStatus('Processing...');
        const accessToken = await getAuthToken();
        if (accessToken) {
            const paymentToken = await createPaymentToken(accessToken);
            if (paymentToken) {
                const secondAccessToken = await getSecondAuthToken();
                if (secondAccessToken) {
                    const transactionResult = await processPayment(secondAccessToken, paymentToken, {
                      clientAccnum: formData.clientAccnum,
                      clientSubacc: formData.clientSubacc,
                      initialPrice: formData.initialPrice,
                      initialPeriod: formData.initialPeriod,
                      currencyCode: formData.currencyCode,
                      recurringPrice: formData.recurringPrice,
                      recurringPeriod: formData.recurringPeriod,
                      rebills: formData.rebills,
                      lifeTimeSubscription: formData.lifeTimeSubscription,
                      createNewPaymentToken: formData.createNewPaymentToken,
                      passThroughInfo: formData.passThroughInfo,
                      username: formData.username,
                      password: formData.password,
                      email: formData.email,
                      expiration: "12/31/2099"
                  });
                    // if (transactionResult && transactionResult.success) {
                    //     setTransactionStatus('Transaction successful!');
                    // } else {
                    //     setTransactionStatus('Transaction failed.');
                    // }
                    if (transactionResult && transactionResult.approved) {
                      setTransactionStatus('Transaction successful!');
                    } else {
                        setTransactionStatus('Transaction failed.');
                    }
                } else {
                    setTransactionStatus('Failed to obtain second access token.');
                }
            } else {
                setTransactionStatus('Failed to create payment token.');
            }
        } else {
            setTransactionStatus('Failed to obtain access token.');
        }
    };

    // Fetch access token from your API
    const getAuthToken = async () => {
        try {
            const response = await fetch('/api/get_ccbill_token', {
                method: 'POST',
                body: JSON.stringify({
                    username: formData.username,
                    password: formData.password
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            console.log('Token:', data.access_token);
            return data.access_token;
        } catch (error) {
            console.error('Error obtaining OAuth token from backend:', error);
            return null;
        }
    };

    // Fetch second access token from your API
    const getSecondAuthToken = async () => {
        try {
            const response = await fetch('/api/get_ccbill_back_token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            console.log('Second Token:', data.access_token);
            return data.access_token;
        } catch (error) {
            console.error('Error obtaining second OAuth token from backend:', error);
            return null;
        }
    };

    // Create payment token using direct API request
    const createPaymentToken = async (accessToken) => {
      const url = 'https://api.ccbill.com/payment-tokens/merchant-only';
      const headers = {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
      };
      const data = {
          "clientAccnum": 948700,
          "clientSubacc": 171,
          "customerInfo": {
              "customerFname": formData.firstName,
              "customerLname": formData.lastName,
              "address1": formData.address1,
              "address2": formData.address2,
              "city": formData.city,
              "state": formData.state,
              "zipcode": formData.postalCode,
              "country": formData.country,
              "phoneNumber": formData.phoneNumber,
              "email": formData.email,
              "ipAddress": formData.ipAddress,
              "browserHttpUserAgent": "",
              "browserHttpAccept": formData.browserHttpAccept,
              "browserHttpAcceptLanguage": formData.browserHttpAcceptLanguage,
              "browserHttpAcceptEncoding": formData.browserHttpAcceptEncoding
          },
          "paymentInfo": {
              "creditCardPaymentInfo": {
                  "cardNum": formData.cardNumber,
                  "nameOnCard": formData.nameOnCard,
                  "expMonth": formData.expMonth,
                  "expYear": formData.expYear,
                  "cvv2": formData.cvv2
              }
          },
          "subscriptionId": 0,
          "timeToLive": 30,
          "validNumberOfUse": 3
      };

      try {
          const response = await fetch(url, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(data)
          });
          const responseData = await response.json();
          console.log('Response data:', responseData);
          const paymentToken = responseData.paymentTokenId;
          console.log('Payment Token Created Successfully');
          console.log('Payment Token:', paymentToken);
          return paymentToken;
      } catch (error) {
          console.error('Failed to create payment token:', error.message);
          return null;
      }
  };

    // Process payment through your API
    // const processPayment = async (accessToken, paymentToken) => {
    //     console.log(paymentToken);
    //     try {
    //         const response = await fetch(`https://api.ccbill.com/transactions/payment-tokens/${paymentToken}`, {
    //             method: 'POST',
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer ${accessToken}`,
    //                 "Accept": "application/vnd.mcn.transaction-service.api.v.1+json"
    //             },
    //             body: JSON.stringify({
    //                 clientAccnum: 948700,
    //                 clientSubacc: 172,
    //                 initialPrice: 14.99,
    //                 initialPeriod: 30,
    //                 recurringPrice: 14.99,
    //                 recurringPeriod: 30,
    //                 rebills: 99,
    //                 currencyCode: 840,
    //                 lifeTimeSubscription: false,
    //                 createNewPaymentToken: false,
    //                 passThroughInfo: [
    //                     { name: "custom1", value: "10000000942" },
    //                     { name: "custom2", value: "10058" }
    //                 ]
    //             })
    //         });
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok.');
    //         }
    //         return await response.json();
    //     } catch (error) {
    //         console.error("Error processing payment:", error);
    //         return null;
    //     }
    // };
    const processPayment = async (accessToken, paymentToken, data) => {
      try {
          const response = await fetch('/api/process_payment', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  accessToken,
                  paymentToken,
                  formData: data
              })
          });
          if (!response.ok) {
              throw new Error('Network response was not ok.');
          }
          const jsonResponse = await response.json();

          if (jsonResponse.approved) {
        
            setTransactionStatus('Approved, welcome to the home page...');
            setTimeout(() => {
                //window.location.href = '/';
            }, 5000);
          } else {
                setTransactionStatus('Transaction failed.');
          }
          return jsonResponse;
      } catch (error) {
          console.error('Error processing payment:', error);
          return null;
      }
  };

    return (
        <div>
            {/* <form onSubmit={handleSubmit}>
                <h2>Payment Form</h2>
                {Object.entries(formData).map(([key, value]) => (
                    <div key={key}>
                        <label>{key.replace(/([A-Z])/g, ' $1').trim()}:</label>
                        <input
                            type={key === 'password' ? 'password' : 'text'}
                            id={`_ccbillId_${key}`}
                            name={key}
                            value={value}
                            onChange={handleChange}
                            required={!(key.includes('optional'))}
                        />
                    </div>
                ))}
                <button type="submit">Submit Payment</button>
                {transactionStatus && <p>{transactionStatus}</p>}
            </form> */}
            <form onSubmit={handleSubmit}>
                <h2>Payment Form</h2>
                <div>
                    <label>Username:</label>
                    <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Name on Card:</label>
                    <input
                        type="text"
                        name="nameOnCard"
                        value={formData.nameOnCard}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Card Number:</label>
                    <input
                        type="text"
                        name="cardNumber"
                        value={formData.cardNumber}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Expiration Month:</label>
                    <select
                        name="expMonth"
                        value={formData.expMonth}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Month</option>
                        {months.map(month => (
                            <option key={month} value={month}>
                                {month}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Expiration Year:</label>
                    <select
                        name="expYear"
                        value={formData.expYear}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Year</option>
                        {years.map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>CVV:</label>
                    <input
                        type="text"
                        name="cvv2"
                        value={formData.cvv2}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>First Name:</label>
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Last Name:</label>
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Address 1:</label>
                    <input
                        type="text"
                        name="address1"
                        value={formData.address1}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Address 2:</label>
                    <input
                        type="text"
                        name="address2"
                        value={formData.address2}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label>City:</label>
                    <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>State:</label>
                    <input
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Country:</label>
                    <input
                        type="text"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Postal Code:</label>
                    <input
                        type="text"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Phone Number:</label>
                    <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Submit Payment</button>
                {transactionStatus && <p>{transactionStatus}</p>}
            </form>
        </div>
    );
};

export default PaymentForm;


// import React, { useState, useEffect } from 'react';

// const PaymentForm = () => {
//     const [formData, setFormData] = useState({
//         nameOnCard: 'John Doe',
//         cardNumber: '4242424242424242',  // A commonly used test VISA card number
//         expMonth: '12',
//         expYear: '2030',  // Ensure the year is in the future
//         cvv2: '123',
//         firstName: 'John',
//         lastName: 'Doe',
//         customerName: 'John Doe',  // Assuming this is required
//         address1: '123 Main St',
//         address2: 'Suite 500',
//         city: 'Anytown',
//         country: 'US',  // ISO 3166-1 alpha-2 country code
//         state: 'CA',
//         postalCode: '90210',
//         phoneNumber: '555-1234',
//         email: 'john.doe@example.com',
//         currencyCode: '840', // USD
//         ipAddress: '192.168.1.1', // Example IP
//         customerInfo: JSON.stringify({ info: "Example customer information" }),  // Example structured data
//         browserHttpAccept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
//         browserHttpAcceptEncoding: 'gzip, deflate, br',
//         browserHttpAcceptLanguage: 'en-US,en;q=0.5'
//     });
//     const [authToken, setAuthToken] = useState('');
//     const [transactionStatus, setTransactionStatus] = useState('');

//   // Dynamically load the CCBill widget script
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = "https://js.ccbill.com/v1.3.0/ccbill-advanced-widget.js";
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   // Handle form data changes
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({ ...prevState, [name]: value }));
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setTransactionStatus('Processing...');
//     const accessToken = await getAuthToken();
//     if (accessToken) {
//       const paymentToken = await fetchPaymentToken(accessToken);
//       if (paymentToken) {
//         const transactionResult = await processPayment(accessToken, paymentToken);
//         if (transactionResult && transactionResult.success) {
//           setTransactionStatus('Transaction successful!');
//         } else {
//           setTransactionStatus('Transaction failed.');
//         }
//       } else {
//         setTransactionStatus('Failed to create payment token.');
//       }
//     } else {
//       setTransactionStatus('Failed to obtain access token.');
//     }
//   };

//   // Fetch access token from your API
//   const getAuthToken = async () => {
//     try {
//       const response = await fetch('/api/get_ccbill_token', {
//         method: 'POST'
//       });
//       const data = await response.json();
//       console.log('Token:', data.access_token);
//       return data.access_token;
//     } catch (error) {
//       console.error('Error obtaining OAuth token from backend:', error);
//       return null;
//     }
//   };

//  // Fetch payment token using the CCBill widget
//  // Fetch payment token using the CCBill widget
// const fetchPaymentToken = async (accessToken) => {
//     return new Promise((resolve, reject) => {
//         if (!window.ccbill) {
//             reject("CCBill widget script not loaded.");
//             return;
//         }

//         try {
//             // Initialize the CCBill widget with your application ID
//             const widget = new window.ccbill.CCBillAdvancedWidget('e207025af05611eeb877d7aeb3b3a550');

//             // Call the createPaymentToken method with provided parameters
//             widget.createPaymentToken(
//                 accessToken, // OAuth token
//                 948700, // Client account number
//                 171, // Client sub-account number
//                 false, // Optionally clear payment info after the transaction
//                 false, // Optionally clear customer info after the transaction
//                 null, // Time to live for the token, null if not applicable
//                 null // Number of uses for the token, null if not applicable
//             ).then(
//                 (response) => {
//                     console.log("Token creation SUCCESS", response);
//                     if (response.paymentToken) {
//                         const paymentTokenUrl = `https://api.ccbill.com/transactions/payment-tokens/${response.paymentToken}`;
//                         console.log("Payment Token URL:", paymentTokenUrl);
//                         resolve(paymentTokenUrl);
//                     } else {
//                         reject("No payment token returned.");
//                     }
//                 }
//             ).catch((error) => {
//                 console.error("Error during token creation", error);
//                 reject(error);
//             });
//         } catch (error) {
//             console.error("Exception caught during token creation", error);
//             reject(error);
//         }
//     });
// };


//   // Process payment through your API
//   const processPayment = async (accessToken, paymentToken) => {
//     console.log(paymentToken)
//     try {
//       const response = await fetch(`https://api.ccbill.com/transactions/payment-tokens/${paymentToken}`, {
//         method: 'POST',
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${accessToken}`
//         },
//         body: JSON.stringify({
//           // Additional data needed for the transaction
//         })
//       });
//       if (!response.ok) {
//         throw new Error('Network response was not ok.');
//       }
//       return await response.json();
//     } catch (error) {
//       console.error("Error processing payment:", error);
//       return null;
//     }
//   };

//   return (
//     <div>
//        <form onSubmit={handleSubmit}>
//         <h2>Payment Form</h2>
//         {Object.entries(formData).map(([key, value]) => (
//           <div key={key}>
//             <label>{key.replace(/([A-Z])/g, ' $1').trim()}:</label>
//             <input
//               type="text"
//               id={`_ccbillId_${key}`} // Assuming the id needs to be prefixed with '_ccbillId_'
//               name={key}
//               value={value}
//               onChange={handleChange}
//               required={!(key.includes('optional'))} // Assuming optional fields are not required
//             />
//           </div>
//         ))}
//         <button type="submit">Submit Payment</button>
//         {transactionStatus && <p>{transactionStatus}</p>}
//       </form>
//     </div>
//   );
// };

// export default PaymentForm;
