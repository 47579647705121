import React from 'react';
import { Container } from 'react-bootstrap';

const Contact = () => {
  return (
    <Container className="contact-page">
      <h1 className="text-center my-4">Contact Us</h1>
      <p className="text-center">
        If you would like us to link to you, please email us at <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a>
      </p>
      <div className="main">
        <p>If you would like us to link to you, please email us at <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a></p>
        <h2>Muscletease</h2>
        <p>
          1111 W. El Camino Real #133 PMB 175<br />
          Sunnyvale, CA 94087<br />
          USA
        </p>
        <p>For membership questions: <a href="mailto:support@ccbill.com">support@ccbill.com</a></p>
        <p>
          In order to cancel your subscription from rebilling, please contact CCBill's Consumer Support department - <a href="https://support.ccbill.com/">https://support.ccbill.com/</a>
        </p>
        <h2>Reporting Content:</h2>
        <p>To report content, please email us at <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a> or use the "Report Content" feature on our website. Include the URL and a brief description of the issue.</p>
        <h2>Review Process:</h2>
        <ul>
          <li>We will acknowledge your report within 48 hours.</li>
          <li>Our team will review the content and take appropriate action.</li>
          <li>We aim to resolve complaints and inform you of the outcome within seven business days.</li>
        </ul>
        <h2>Appeals:</h2>
        <p>If you have been depicted in any content and would like to appeal removal of such content, please notify us by sending an email here: <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a></p>
        <p>If there should be a disagreement regarding an appeal, we will allow the disagreement to be resolved by a neutral body.</p>
        <h2>Appeals Process:</h2>
        <p>If your content has been removed, you can appeal the decision by contacting us at <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a>. We will provide a final decision within 14 business days.</p>
        <h2>Outcomes:</h2>
        <p>Outcomes may include removal of content, suspension of accounts, or no action if the content complies with our standards.</p>
        <h2>Confidentiality:</h2>
        <p>All reports and investigations are treated confidentially. For more information, contact us at <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a>.</p>
      </div>
    </Container>
  );
};

export default Contact;
