import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Vod from './components/Vod';
import Photos from './components/Photos';
import Videos from './components/Videos';
import Join from './components/Join';
import Models from './components/Models';
import Links from './components/Links';
import Contact from './components/Contact';
import Forum from './components/Forum';
import Free from './components/Free';
import Navigation from './components/Navigation'; // Navigation component to be created
import VideoDetails from './components/VideoDetails';
import VideoDisplay from './components/VideoDisplay';
import PhotoDetails from './components/PhotoDetails';
import ImageDisplay from './components/ImageDisplay'; // Component to display images
import FreeImageDisplay from './components/FreeImageDisplay'; // Component to display images
import FreePhotoDetails from './components/FreePhotoDetails';
import VodDetails from './components/VodDetails';
import ModelDetails from './components/ModelDetails';
import Search from './components/Search';
import Affiliates from './components/Affiliates';
import TwoTwoFiveSeven from './components/2257';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import PrivateSales from './components/PrivateSales';
import PaymentForm from './components/PaymentForm';
const TestComponent = () => <div>Test Route Match</div>;

function App() {
  return (
    <Router>
      {/*<div className="App">
        <header className="App-header">
          <p>Welcome to Muscletease.net, we will be moving from muscletease.com over here.</p>
          <p>You can join temporarily by emailing your desired username and password to muscletease@gmail.com and <b>send a paypal payment of $14.99 to johntherevelator6969@gmail.com</b></p>
          <p>if you are buying a VOD, please also indicate which one and send amount as well as username and password.</p>
          <p>You can also subscribe below temporarily too but please email muscletease@gmail.com your username and password:</p>
          <div className="iframe-container">
            <iframe src={`${process.env.PUBLIC_URL}/join.html`} title="Join" style={{width: '100vw', height: '100vh', border: 'none'}}></iframe>
          </div>
        </header>
      */}
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/vod" element={<Vod />} />
        <Route path="/photos" element={<Photos />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/join" element={<Join />} />
        <Route path="/models" element={<Models />} />
        <Route path="/links" element={<Links />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/free" element={<Free />} />
        <Route path="/video/:foldername" element={<VideoDetails />} />
        <Route path="/photos/:foldername" element={<PhotoDetails />} />
        <Route path="/vod/:foldername" element={<VodDetails />} />
        <Route path="/test" element={<TestComponent />} />
        <Route path="/models/:modelname" element={<ModelDetails />} />
        <Route path="/modelname/:modelname" element={<ModelDetails />} />
        <Route path="/members/photos/:folderName/:imageName" element={<ImageDisplay />} />
        <Route path="/members/video/:foldername/:filename" element={<VideoDisplay />} />
        <Route path="/freephotos/:foldername" element={<FreePhotoDetails />} />
        <Route path="/members/freephotos/:folderName/:imageName" element={<FreeImageDisplay />} />
        <Route path="/search/:searchTerm" element={<Search />} />
        <Route path="/2257" element={<TwoTwoFiveSeven />} />
        <Route path="/privatesales" element={<PrivateSales />} />
        <Route path="/ccbill" element={<PaymentForm />} />
        <Route path="/affiliates" element={<Affiliates />} />
      </Routes>
     {/* </div> */}
    </Router>
    
  );
}

export default App;
