import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import './ModelDetails.css';

const ModelDetails = () => {
  const { modelname } = useParams();
  const [modelDetails, setModelDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const encodedModelName = encodeURIComponent(modelname);
    const fetchModelDetails = async () => {
      try {
        const url = `/api/modelname/${encodedModelName}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setModelDetails(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchModelDetails();
  }, [modelname]);

  if (isLoading) return <Container>Loading...</Container>;
  if (error) return <Container>Error: {error}</Container>;
  if (!modelDetails) return <Container>No Model Details Available</Container>;

  return (
    <Container className="model-detail-container">
      <h2 className="text-center">{decodeURIComponent(modelname)}</h2>

      {/* Photos Section */}
      <Row className="my-4">
        <h3>Photos</h3>
        {modelDetails.photos.map((photo) => (
          <Col key={photo.photo_id} xs={6} sm={4} md={3} lg={2}>
            <Link to={`/photos/${photo.path}`}>
              <div className="model-detail-thumbnail-wrapper">
                <img src={`/members/photosthumbs/${photo.path}/large.jpg`} 
                     alt={photo.updatename} 
                     className="model-detail-thumbnail" />
              </div>
              <div className="model-detail-info">{photo.updatename}</div>
            </Link>
          </Col>
        ))}
      </Row>

      {/* Videos Section */}
      <Row className="my-4">
        <h3>Videos</h3>
        {modelDetails.videos.map((video) => (
          <Col key={video.video_id} xs={6} sm={4} md={3} lg={2}>
            <Link to={`/video/${video.path}`}>
              <div className="video-vod-thumbnail-wrapper">
                <img src={`/members/videothumbs/${video.path}/${video.path}_02.jpg`} 
                     alt={video.updatename} 
                     className="video-vod-thumbnail" />
              </div>
              <div className="model-detail-info">{video.updatename}</div>
            </Link>
          </Col>
        ))}
      </Row>

      {/* VODs Section */}
      <Row className="my-4">
        <h3>VODs</h3>
        {modelDetails.vods.map((vod) => (
          <Col key={vod.vod_id} xs={6} sm={4} md={3} lg={2}>
            <Link to={`/vod/${vod.path}`}>
              <div className="video-vod-thumbnail-wrapper">
                <img src={`/members/vodthumbs/${vod.path}/${vod.path}_02.jpg`} 
                     alt={vod.updatename} 
                     className="video-vod-thumbnail" />
              </div>
              <div className="model-detail-info">{vod.updatename}</div>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ModelDetails;
