import React from 'react';
import { Container } from 'react-bootstrap';

const Join = () => {
  return (
    <Container className="join-page">
        <p>You can join temporarily by emailing your desired username and password to <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a> and <b>send a paypal payment of $14.99 to johntherevelator6969@gmail.com</b></p>
        <p>If you are buying a VOD, please also indicate which one and send amount as well as username and password, VODs is not part of the membership bear that in mind.</p>
        <p>You can also subscribe below temporarily too but please email <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a> your username and password:</p>
        <div className="iframe-container">
          <iframe src={`${process.env.PUBLIC_URL}/join.html`} title="Join" style={{ width: '400px', height: '600px', border: 'none' }}></iframe>
        </div>
        <p>If you would like to cancel your subscription from rebilling, please contact <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a></p>
        <p>In order to cancel your subscription from rebilling, please contact CCBill's Consumer Support department at <a href="https://support.ccbill.com/">https://support.ccbill.com/</a>.</p>
    </Container>  
  );
};

export default Join;
