import React from 'react';

const Free = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Free</h1>
      <p>Coming Soon</p>
    </div>
  );
};

export default Free;
