import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import './Videos.css';

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page')) || 1;
  const pageSize = 10;

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `/api/videos/${page}`;
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        setVideos(json);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const changePage = (newPage) => {
    setSearchParams({ page: newPage });
  };

  if (isLoading) return <Container>Loading...</Container>;
  if (error) return <Container>Error: {error}</Container>;

  return (
    <Container className="video-gallery">
      <h1 className="text-center my-4">Videos</h1>
      <Row xs={2} md={3} lg={5} className="g-4">
        {videos.map((video) => (
          <Col key={video.video_id} className="video-column">
            <Link to={`/video/${video.path}`} className="video-link">
              <Card className="custom-card">
                <div className="video-thumbnail">
                  <img
                    src={`/members/videothumbs/${video.path}/${video.path}_02.jpg`}
                    alt={video.modelname}
                    className="img-fluid"
                  />
                </div>
                <Card.Body>
                  <Card.Title className="video-title">{video.updatename}</Card.Title>
                  <Card.Text>Uploaded: {video.datetoupload}</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
      <Row className="pagination-row">
        <Col className="d-flex justify-content-center my-4">
          <button onClick={() => changePage(page - 1)} disabled={page === 1} className="btn btn-secondary mx-2">Previous</button>
          <button onClick={() => changePage(page + 1)} disabled={videos.length < pageSize} className="btn btn-secondary mx-2">Next</button>
        </Col>
      </Row>
    </Container>
  );
};

export default Videos;
