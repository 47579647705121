import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Links.css'; // Make sure to create a CSS file for styling

const Links = () => {
  return (
    <Container className="links-page">
      <Row className="justify-content-center">
        <Col md={10}>
          <h1 className="text-center my-4">Links to Fellow Sites and Model Sites</h1>
          <p className="text-center">If you would like us to link to you please email us at <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a></p>
          <div className="text-center my-4">
            <a href="http://www.muscletease.com/"><img src="banners/muscletease1.jpg" alt="Muscletease" className="img-fluid" /></a>
          </div>
          <h2 className="text-center">Fellow Sites</h2>
          <div className="text-center my-4">
            <a href="http://www.thevalkyrie.com/"><img src="banners/dtvbanner.gif" alt="The Valkyrie" className="img-fluid" /></a>
          </div>
          <div className="text-center my-4">
            <a href="http://www.hardfitness.com/"><img src="banners/hardfitnesslink.gif" alt="HardFitness Online Magazine" className="img-fluid" /></a>
          </div>
          {/* Add more links and images as needed */}
          <div className="text-center my-4">
            <a href="http://www.nebiros.com/"><img src="banners/nebirosbanner.jpg" alt="Nebiros" className="img-fluid" /></a>
          </div>
          <div className="text-center my-4">
            <a href="http://www.andysmusclegoddesses.us/"><img src="banners/Andy.jpg" alt="Andy's Muscle Goddesses" className="img-fluid" /></a>
          </div>
          <div className="text-center my-4">
            <a href="http://www.muscleangels.com/"><img src="banners/muscleangels_banner.jpg" alt="Muscle Angels" className="img-fluid" /></a>
          </div>
          <div className="text-center my-4">
            <a href="http://www.ultimateamazons.com/"><img src="banners/ultimateamazonbanner.gif" alt="Ultimate Amazons" className="img-fluid" /></a>
          </div>
          <div className="text-center my-4">
            <a href="http://www.sensuflex.com/"><img src="banners/Sensuflex_banner78.jpg" alt="Sensuflex" className="img-fluid" /></a>
          </div>
          <div className="text-center my-4">
            <a href="http://www.athleticwomenmagazine.com/"><img src="banners/aw_mag.jpg" alt="Athletic Women Magazine" className="img-fluid" /></a>
          </div>
          <div className="text-center my-4">
            <a href="http://www.herflexappeal.com/"><img src="banners/her_flex_appeal.jpg" alt="Her Flex Appeal" className="img-fluid" /></a>
          </div>
          <div className="text-center my-4">
            <a href="http://www.delimit.net/~deekay/"><img src="banners/deekay.gif" alt="Muscles of Dee Kay" className="img-fluid" /></a>
          </div>
          <div className="text-center my-4">
            <a href="http://www.tomnine.com/"><img src="banners/t9banner.jpg" alt="TomNine.com" className="img-fluid" /></a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Links;
