import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const VideoDetails = () => {
  const { foldername } = useParams();
  const [prename, setPrename] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrename = async () => {
      try {
        const response = await fetch(`/api/videos/prename/${foldername}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setPrename(data.prename);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrename();
  }, [foldername]);

  if (isLoading) return <Container>Loading...</Container>;
  if (error) return <Container>Error: {error}</Container>;

  const getFileExtension = (prename) => {
    // Check if prename already has a valid extension
    if (prename.endsWith("001.mp4") || prename.endsWith("001.mpg") || prename.endsWith("001.wmv")) {
      return prename;
    }
  
    // Determine the extension based on the presence of HD, HR or neither
    if (prename.includes("HD")) {
      return prename + "001.mp4";
    } else if (prename.includes("HR")) {
      return prename + "001.mpg";
    } else {
      // Default to .mpg if neither HD nor HR is present
      return prename + "001.mpg";
    }
  };

  return (
    <Container className="video-detail-container">
      <h2>Video Details for {foldername}</h2>
      <Row>
        {['02', '05', '10', '15'].map(second => (
          <Col key={second} xs={6} md={4} lg={3}>
            <img src={`/members/videothumbs/${foldername}/${foldername}_${second}.jpg`}
                 alt={`Thumbnail ${second}`}
                 className="img-fluid" />
          </Col>
        ))}
      </Row>
      <div className="d-flex justify-content-center mt-4">
      <a href={`/members/video/${foldername}/${getFileExtension(prename)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-primary">
        Download
      </a>
      </div>
    </Container>
  );
};

export default VideoDetails;

