import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

const VodDetails = () => {
  const { foldername } = useParams();
  const [vodDetails, setVodDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVodDetails = async () => {
      try {
        const response = await fetch(`/api/vod/prename/${foldername}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setVodDetails(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVodDetails();
  }, [foldername]);

  const getDownloadLink = () => {
    if (!vodDetails) return '#';
    const dateThreshold = new Date('2024-01-28');
    const vodDateParts = vodDetails.datetoupload.split('/');
    const vodDate = new Date(vodDateParts[2], vodDateParts[1] - 1, vodDateParts[0]);
    //const folderName = vodDate >= dateThreshold ? vodDetails.prename : vodDetails.path;
    //const folderName = vodDate >= dateThreshold ? vodDetails.path : vodDetails.prename;
    const folderName = vodDetails.path;
    const fileExtension = vodDetails.format === '0' ? 'mp4' : 'mpg';

    //return `/members/vod/${folderName}/${folderName}.${fileExtension}`;
    //return `/members/vod/${folderName}/${vodDetails.prename.split('.')[0]}.${fileExtension}`;
    if (vodDate >= dateThreshold) {
      return `/members/vod/${folderName}/${vodDetails.prename.split('.')[0]}.${fileExtension}`;
    } else {
      return `/members/vod/${folderName}/${folderName}.${fileExtension}`;
    }
  };

  if (isLoading) return <Container>Loading...</Container>;
  if (error) return <Container>Error: {error}</Container>;
  if (!vodDetails) return <Container>No VOD details found.</Container>;

  return (
    <Container className="vod-detail-container">
      <h2>VOD Details for {foldername}</h2>
      <Row>
        {['02', '05', '10', '15'].map((second, index) => (
          <Col key={index} xs={6} md={4} lg={3}>
            <img 
              src={`/members/vodthumbs/${foldername}/${foldername}_${second}.jpg`}
              alt={`Thumbnail at ${second} seconds`} 
              className="img-fluid" />
          </Col>
        ))}
      </Row>
      <div className="d-flex justify-content-center mt-4">
        <a
          href={getDownloadLink()}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary me-2">
          Download
        </a>
        <Link to="/join" className="btn btn-secondary">
          Buy for {vodDetails.price}
        </Link>
      </div>
    </Container>
  );
};

export default VodDetails;
