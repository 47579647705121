import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Vod.css';

const Vod = () => {
  const [vods, setVods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const loader = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const apiUrl = `/api/vod/${currentPage}`;
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        setVods((prevVods) => [...prevVods, ...json]);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: null,
        rootMargin: '20px',
        threshold: 0,
      }
    );

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, []);

  useEffect(() => {
    // Restore scroll position
    const savedScrollPosition = sessionStorage.getItem('vodScrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition));
    }

    return () => {
      // Save scroll position
      sessionStorage.setItem('vodScrollPosition', window.scrollY);
    };
  }, []);

  if (error) return <Container>Error: {error}</Container>;

  return (
    <Container className="vod-gallery">
      <h1 className="text-center my-4">VODs</h1>
      <Row xs={2} md={2} lg={5} className="g-3">
      {vods.map((vod, index) => (
        <Col key={`${vod.vod_id}-${index}`} className="vod-column">
          <Link to={`/vod/${vod.path}`} className="vod-link">
            <Card className="custom-card">
              <div className="vod-thumbnail">
                <img
                  src={`/members/vodthumbs/${vod.path}/${vod.path}_02.jpg`}
                  alt={vod.modelname}
                  className="img-fluid"
                />
              </div>
              <Card.Body>
                <Card.Title>{vod.updatename}</Card.Title>
                <Card.Text>Uploaded: {vod.datetoupload}</Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      ))}
      </Row>
      {isLoading && <div>Loading more VODs...</div>}
      <div ref={loader} />
    </Container>
  );
};

export default Vod;
