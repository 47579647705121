import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import './Photos.css'; // Make sure to include your custom CSS

const Photos = () => {
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page')) || 1;
  const pageSize = 10; // Adjust as needed

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `/api/photos/${page}`;
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        setPhotos(json);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const changePage = (newPage) => {
    setSearchParams({ page: newPage });
  };

  if (isLoading) return <Container>Loading...</Container>;
  if (error) return <Container>Error: {error}</Container>;

  return (
    <Container className="photo-gallery">
      <h1 className="text-center my-4">Photos</h1>
      <Row xs={2} md={3} lg={5} className="g-4">
        {photos.map((photo) => (
          <Col key={photo.photos_id} md={2} className="mb-4">
            <Card className="search-card">
              <Link to={`/photos/${photo.path}`}>
                <div className="image-container">
                  <Card.Img variant="top" src={`/members/photosthumbs/${photo.path}/large.jpg`} />
                </div>
                <Card.Body>
                  <Card.Title>{photo.updatename}</Card.Title>
                  <Card.Text>{photo.modelname}</Card.Text>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="pagination-row">
        <Col className="d-flex justify-content-center my-4">
          <button onClick={() => changePage(page - 1)} disabled={page === 1} className="btn btn-secondary mx-2">Previous</button>
          <button onClick={() => changePage(page + 1)} disabled={photos.length < pageSize} className="btn btn-secondary mx-2">Next</button>
        </Col>
      </Row>
    </Container>
  );
};

export default Photos;
