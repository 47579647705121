import React from 'react';

const TwoTwoFiveSeven = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="text-center my-4">2257 Compliance Statement</h1>
        <p className="text-center">All models appearing on this website were over the age of 18 at the time of filming.</p>
        <p className="text-center">In compliance with the Federal Labeling and Record-Keeping Law (also known as 18 U.S.C. 2257), all models located within our domain were 18 years of age or older during the time of photography. All models' proof of age is held by the Custodian of Records.</p>
        <address className="text-center">
          Muscletease<br />
          Summit House, 170 Finchley Road<br />
          London, NW3 6BP<br />
          United Kingdom<br />
          <br />
          Custodian of Records: <a href="mailto:muscletease@gmail.com">muscletease@gmail.com</a>
        </address>
        <p className="text-center">For further assistance and/or information in finding the content's originating site, please contact muscletease@gmail.com.</p>
      </header>
    </div>
  );
};

export default TwoTwoFiveSeven;
